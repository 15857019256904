.HeadTop {
    background-color: white;
    padding: 22px;
    border: 1px solid #DCE0E5;
}

.btnElq {
    float: right;
}

@primary-color: #40a9ff;