.d-flex {
    display: inline-flex;
}
.mr-10 {
    margin-right: 10px;
}
.ml-10 {
    margin-left: 10px;
}
.boldLabel {
    margin-top: 25px;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 1.17rem !important;
    .ant-checkbox-wrapper {
        margin-left: 10px;
    }
}
.checkboxParent {
    input {
      width: 28px;
      height: 28px;
      &:checked {
       + .ant-checkbox-inner {
           background-color: #2588EB;
       } 
      }
    }
    .ant-checkbox-inner {
      background-color: #dfdfdf;
      width: 28px;
      height: 28px;
      border-radius: 3px;
      left: 0 !important;
      border: 0 !important;
      &::after {
        height: 15.714286px;
        width: 9.142857px;
        top: 47%;
        left: 25%;
      }
    }
  }

  .headerSearchContainer {
    input {
        border: none;
        border-radius: 22px;
        
        box-shadow: none !important;
        &:focus {
          box-shadow: none !important;
        }
    }
    .ant-input-group {
        border: 1px solid rgba(166, 166, 171, 0.5) !important;
        border-radius: 22px;
        overflow: hidden;
    }
    .ant-input-group-addon {
        border: none;
        background-color: transparent;
        button {
            border: none;
            background-color: transparent !important;
            .anticon-search  {
              color: rgba(166, 166, 171, 0.5);
            }
        }
        
    }
}
.btnChangers {
  border-radius: 50%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  padding: 0;
  background-color: #EDEFF2;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
      background-color: #cbced1;
  }
}
@primary-color: #40a9ff;