.error-box {
	display: flex;
	height: 100vh;
	justify-content: center;
	align-items: center;
}
.error-404,
.error-text {
	display: flex;
    justify-content: center;
    text-align: center;
}

.error-text p {
    font-size: 20px;
}

.error-text h2 {
    font-size: 25px;
}

.error-404 {
	height: 40vh;
}
.f404 {
	display: inline-block;
	cursor: context-menu;
	font-size: 200px;
	font-size: 28vh;
	text-align: center;
	color: #ccc;
	text-shadow: 0 10vh 40px rgba(0, 0, 0, 0.1);
	transition: 0.3s;
}
.f404:hover,
.f404:active {
	margin-top: 3vh;
	font-size: 175px;
	font-size: 24.5vh;
	color: #40a9ff;
}

#f404-1,
#f404-2,
#f404-3 {
	animation: float 2s infinite ease-in-out alternate;
}
#f404-2 {
	animation-delay: 0.4s;
}
#f404-3 {
	animation-delay: 0.8s;
}

@keyframes float {
	100% {
		transform: translateY(20px);
	}
}