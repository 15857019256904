.sidebar {
    text-align: left;

    ul {
        background-color: #3c4b64;
    }
    
    h1 {
        color: white;
        text-align: center;
    }

    li:last-child{
        margin-top: 115px;
    }

    li:first-child{
        padding-bottom: 4px;
    }

    li:first-child h1{
        margin-bottom: 0;
    padding: 7px;
    }

    li:nth-child(2){
        margin-top: 0;
    }

}

@primary-color: #40a9ff;