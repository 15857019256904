.slides-container {
  .items-container {
    display: flex;
    flex-wrap: wrap;
    .slidesItem {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding-bottom: 10px;
      flex-direction: column;
      text-align: center;
      margin-right: 20px;
      width: 150px;
      a {
        font-size: 20px;
        width: 120px;
        height: 120px;
        margin-bottom: 20px;
        color: #000;
        .ant-avatar-image {
          width: 120px !important;
          height: 120px !important;
          img {
            width: 120px;
            height: 120px;
          }
        }
      }
      .ant-btn-icon-only {
        width: 50px;
        height: 50px;
      }
      &:nth-child(even) {
        background-color: rgba(215, 215, 215, 0.3);
      }
      &:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      }
    }
  }
}

@primary-color: #40a9ff;