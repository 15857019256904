html, body {
  height: 100%;
}

#root {
  background-color: #ebedef;
}

.ant-menu.ant-menu-dark.ant-menu-root.ant-menu-inline {
  height: 100vh;
  position: relative;
}

li.ant-menu-item:last-child {
  position: absolute;
  bottom: 0;
}

.ant-menu-dark .ant-menu-item>a {
  font-size: 16px;
}

.ant-table-wrapper {
  padding: 25px;
}

textarea {
  margin-top: 24px !important;
}

.ant-table-body {
  background: white !important;
}

.ant-spin-nested-loading {
  box-shadow: 0px 0px 3px #00000036;
}

.ant-table-thead>tr>th, .ant-table-tbody>tr>td {
  padding: 5px 13px !important;
}

.ant-table-thead>tr>th .ant-table-header-column {
  font-size: 14px;
}

.ant-table-tbody>tr>td {
  font-size: 14px;
  color: black;
}

.ant-tabs-nav .ant-tabs-tab {
  padding: 12px 25px !important;
  border-radius: 4px;
}

.ant-tabs-nav .ant-tabs-tab-active {
  background-color: white;
}

.ant-tabs-nav-scroll {
  margin-top: 10px;
}

.ant-tabs-ink-bar {
  height: 3px !important;
}

.modalTab .ant-tabs-tab {
  margin-right: 0 !important;
  padding: 10px !important;
}

.modalTab .ant-tabs-ink-bar {
  display: none !important;
}

.modalTab .ant-tabs-bar {
  border-bottom: none;
}

.modalTab .ant-tabs-nav-scroll {
  padding-left: 0;
  margin-left: -7px;
}

.status {
  margin-top: 20px;
}

.status p {
  margin-right: 20px;
  font-weight: bold;
  line-height: 0;
}

.status p, button {
  display: inline-block;
}

.btnModal {
  margin-top: 15px;
}

.plusIcon {
  line-height: 32px;
  font-size: 18px;
  color: #40a9ff;
}

.marg {
  margin-right: 50px;
}

.ant-table-tbody>tr>td, .ant-table-thead>tr>th {
  border-left: 1px solid black;
  border-bottom: 8px solid silver !important;
}

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff !important;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff !important;
}

.btn {
  margin-top: 25px;
  margin-left: 25px;
}

.ant-pagination {
  margin-left: 25px !important;
  padding-bottom: 25px !important;
}

.ant-upload.ant-upload-select {
  margin-top: 20px;
}

.ant-form-item {
  margin-bottom: 20px !important;
}

.jobCreate {
  margin-top: 20px;
}

tbody:hover td {
  background: transparent !important;
}

.ck-editor__editable {
  height: 150px;
}

.ck.ck-editor__editable_inline> :last-child {
  line-height: 24px;
}

.ck-content br {
  display: none;
}

.ck.ck-editor {
  margin-top: 15px;
}

.ant-upload-list-item-card-actions .anticon {
  padding-right: 12px !important;
}

.ck.ck-editor__editable_inline> :first-child {
  margin-top: 0 !important;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  padding-top: 10px;
}

.ant-checkbox-inner {
  left: 2px !important;
}

.editProduct h3 {
  margin-top: 25px;
  margin-bottom: 15px;
}

.ant-modal-confirm-body-wrapper {
  text-align: center !important;
}

.ant-modal-confirm-btns {
  float: initial !important;
}

.ant-modal-confirm-body-wrapper svg {
  position: absolute;
  margin-left: 16px;
  display: block;
}

.ant-modal-confirm-body-wrapper .anticon-question-circle {
  display: contents;
}

.confirm-mod {
  width: 580px !important;
}
.confirm-mod .ant-modal-content {
  border-radius: 22px;
}
.download-attributes {
  width: 1000px !important;
}

.toPrint {
  text-align: center;
}

.toDowload, .toPrint span, .toEdit, .toDelete {
  text-align: center;
  cursor: pointer;
  padding: 10px;
}

.toDowload:hover, .toPrint span:hover, .toEdit:hover, .toDelete:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.toPrint i, .toEdit i, .toDelete i {
  font-size: 18px;
}

.order-table-max-width {
  max-width: 200px;
  width: 200px;
}