.sliderBody {
  padding: 24px;
}
  .adddSliderRow {
    flex-direction: column;
    button {
      display: flex;
      margin-left: auto;
      align-items: center;
    }
  }
  .addSlideMore {
    margin-top: 20px;
  }
  .create-edit-container {
    margin-top: 20px;
    h2 {
      margin-bottom: 20px;
    }
    .ant-upload {
      &.ant-upload-select {
        margin-top: 0;
        display: flex;
        span {
          display: flex;
          justify-content: center;
          width: 100%;
          button {
            display: flex;
            align-items: center;
            width: 100%;
          }
        }
      }
    }
    .ant-form-item-control {
      line-height: inherit;
    }
  }
.deleteSliderModal {
  .ant-modal-content {
    border-radius: 12px;
    .ant-modal-body {
      padding: 15px;
      h1 {
        margin-top: 20px;
        margin-bottom: 30px;
        font-size: 1.17rem;
      }
    }
  }
  .modalParentDeleteSlider {
    .flexItem {
      display: flex;
      .ant-form-item {
        margin-bottom: 0 !important;
        &:first-child {
          margin-right: 10px;
          margin-left: auto;
          button {
            background-color: transparent;
            color: rgba(0, 0, 0, 0.50);
            border-color: rgba(0, 0, 0, 0.20);
          }
        }
        &:last-child {
          button {
            background-color: #ff0000;
            border: none;
          }
        }
      }
    }
  }
}

@primary-color: #40a9ff;